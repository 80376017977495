exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-domofons-js": () => import("./../../../src/pages/domofons.js" /* webpackChunkName: "component---src-pages-domofons-js" */),
  "component---src-pages-furnitura-js": () => import("./../../../src/pages/furnitura.js" /* webpackChunkName: "component---src-pages-furnitura-js" */),
  "component---src-pages-gates-js": () => import("./../../../src/pages/gates.js" /* webpackChunkName: "component---src-pages-gates-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-korobki-js": () => import("./../../../src/pages/korobki.js" /* webpackChunkName: "component---src-pages-korobki-js" */),
  "component---src-pages-mconstructions-js": () => import("./../../../src/pages/mconstructions.js" /* webpackChunkName: "component---src-pages-mconstructions-js" */),
  "component---src-pages-security-js": () => import("./../../../src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-video-js": () => import("./../../../src/pages/video.js" /* webpackChunkName: "component---src-pages-video-js" */),
  "component---src-pages-wooddoors-js": () => import("./../../../src/pages/wooddoors.js" /* webpackChunkName: "component---src-pages-wooddoors-js" */),
  "component---src-templates-door-template-js": () => import("./../../../src/templates/door-template.js" /* webpackChunkName: "component---src-templates-door-template-js" */),
  "component---src-templates-wdoor-template-js": () => import("./../../../src/templates/wdoor-template.js" /* webpackChunkName: "component---src-templates-wdoor-template-js" */)
}

